// Core
import React, { useEffect, useState, useRef } from "react";
import { useMutation } from "react-query";
import { useSelector, useDispatch } from "react-redux";
// import { useParams } from "react-router";
import { useHistory } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import clsx from "clsx";

// Actions
import {
  getChannelMessages,
  setChannelMessages,
  setParticipants,
  setStore,
  clearChannel,
} from "redux/actions/chat-actions";

// Hooks
import { useBreakpoints } from "hooks";

// Components
import { MuiLoader } from "components/common";
import { ChatMessages, ChatInput, ChatBadges } from "components";
import { Alert } from "@material-ui/lab";
import { Typography, Box } from "@material-ui/core";

// Icons
import { ReactComponent as BackIcon } from "theme/icons/back.svg";

// Styles
import { useStyles } from "./chat.styles";

// Tools
import { isEmpty } from "utils/helpers";
import { CHAT_MESSAGES_PER_PAGE } from "utils/constants";

const Chat = ({ socket }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { channelId, channelMessages, store, unreadMessageCount } = useSelector(
    (state) => state.chat
  );
  const sidebarIsOpen = useSelector((state) => state.chat.showChannelsSidebar);
  const { isMobile, isDesktop } = useBreakpoints();
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const scrollableDiv = useRef(null);

  const { error, isLoading, isError, isSuccess, mutate } = useMutation(
    (postData) => dispatch(getChannelMessages(postData)),
    {
      onSuccess: (data) => {
        dispatch(setChannelMessages(data?.messages || []));
        dispatch(setParticipants(data?.participants || []));
        dispatch(setStore(data?.store || {}));
        if (
          data?.messages?.length === 0 ||
          data?.messages?.length < CHAT_MESSAGES_PER_PAGE
        ) {
          setHasMore(false);
        }
      },
    }
  );

  const fetchMoreData = () => {
    setTimeout(() => {
      mutate({ channelId, page: page + 1, perPage: CHAT_MESSAGES_PER_PAGE });
      setPage(page + 1);
    }, 1000);
  };

  const handleBackClick = () => {
    dispatch(clearChannel());
    setTotalCount(null);
    history.push("/chat");
  };

  useEffect(() => {
    if (channelId) {
      setPage(0);
      setHasMore(true);
      mutate({ channelId, page: 0, perPage: CHAT_MESSAGES_PER_PAGE });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelId]);

  useEffect(() => {
    if (!isEmpty(unreadMessageCount)) {
      let alerts = 0;
      let totals = 0;
      Object.values(unreadMessageCount).forEach((obj) => {
        alerts += obj.alerts;
        totals += obj.total;
      });
      const totalCount = alerts + totals;
      setTotalCount(totalCount);
    }
  }, [unreadMessageCount, channelId]);

  return (
    <>
      {isMobile && !sidebarIsOpen && (
        <div className={clsx(classes.chatInfo, classes.container)}>
          <div
            className={clsx(classes.row, classes.back)}
            onClick={handleBackClick}
          >
            <BackIcon className={classes.backIcon} />
            {totalCount > 0 && (
              <span className={classes.counter}>
                {totalCount > 99 ? "99 +" : totalCount}
              </span>
            )}
          </div>
          {!isEmpty(store) && (
            <section className={classes.store}>
              <Box className={classes.storeDescription}>
                <Typography className={classes.title} variant="subtitle2">
                  #{store.store_name}
                </Typography>
                <span className={classes.address}>
                  {store.city}, {store.state}
                </span>
              </Box>
              {channelId && (
                <Box className={classes.storeBadges}>
                  <ChatBadges channelId={channelId} />
                </Box>
              )}
            </section>
          )}
        </div>
      )}

      <div className={classes.messagesWrapper}>
        {isLoading && <MuiLoader fullpage />}
        {isError && <Alert severity="error">{error.message}</Alert>}
        {isSuccess && (
          <div
            id="scrollableDiv"
            ref={scrollableDiv}
            className={clsx(classes.scrollWrapper, "viewportHeight")}
            style={{
              width: "100%",
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={channelMessages?.length}
              next={fetchMoreData}
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                overflow: "visible",
              }}
              inverse={true}
              hasMore={hasMore}
              loader={
                hasMore && (
                  <Typography
                    className={classes.endMessage}
                    variant="body2"
                    color="secondary"
                  >
                    ⏳&nbsp;Loading...
                  </Typography>
                )
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <>
                  {channelMessages?.length !== 0 && (
                    <Typography
                      className={classes.endMessage}
                      variant="body2"
                      color="secondary"
                    >
                      No more messages to load...🤷
                    </Typography>
                  )}
                </>
              }
            >
              <ChatMessages
                messages={channelMessages}
                socket={socket}
                page={page}
              />
            </InfiniteScroll>
          </div>
        )}
      </div>

      {channelId && <ChatInput socket={socket} />}
      {channelId && isDesktop && (
        <Box className={classes.chatBadgesDesktop}>
          <ChatBadges channelId={channelId} />
        </Box>
      )}
    </>
  );
};

export default Chat;
